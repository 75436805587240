@import "../../App.scss";

.homepage{
    background-color: var(--primary-grey) !important;
    border-bottom: none !important;
}

#navbar-container{
    background-color: #FFFFFF;
    border-bottom: 1px solid #D0CFCF;
    display: flex;
    place-content: space-between;
    align-items: center;

    #navbar-title{
        font-family: var(--logo-font);
        font-size: 1.5em;
        font-weight: 700;
    }
    h1{
        padding: 0;
        margin: 0;
    }
    .btn-container{
        display:flex;
        #chain-btn{
            img{
                width: 1.9em;
                margin-right: .5em;
            }
            display: flex;
            align-items: center;
            outline: 0 !important;
            color:var(--primary-yellow);
            border: 2px solid var(--primary-yellow);
            border-radius: 38px;
            font-family: var(--text-font);
            font-size: 1em;
            font-weight: 700;
            background-color: #FFFFFF;
            padding: .1em 1em .1em .2em;
            margin-right: 1em;
        }
        #chain-btn:hover{
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
        #metamask-btn{
            color:white;
            border:2px solid black;
            border-radius: 38px;
            font-family: var(--text-font);
            font-size: 1em;
            font-weight: 700;
            background-color: #000000;
            padding: .5em 1em;
            outline: none;
        }
        #metamask-btn:hover{
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }

    }
    
    .grey{
        background-color: #c4c4c4 !important;
    }
    .yellow{
        background-color: var(--primary-yellow) !important;
    }
    
}