@import "../../App.scss";
@import "./Tooltip.scss";
.card{
    position: fixed relative;
    left: 0;
    top: 10em;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    .changechaincard-container{
        z-index: 1;
        box-shadow: rgba(0, 0, 0, 0.10) 0px 3px 8px;
        border: 1px solid #D0CFCF;
        position: absolute;
        top: 5em;
        left: calc(100% - 25em);
        background-color: white;
        padding: 1.5em;
        border-radius: 10px;
        .changechaincard-text{
            font-weight: 700;
            margin-bottom: .5em;
        }
    }
}
