.tooltip-container {
    position: relative;
    display: inline-flex;
    border-bottom: 1px dotted black;
    //height: 100%;
  }
  
.tooltip-container .tooltip {
    visibility: hidden;
    width: 210px;
    background-color: black;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: .5em .8em;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 110%;
    left: 90%;
    margin-left: -60px;
}
.tooltip{
    font-size: .8em;
    //display: flex;
    line-height: 1.5em;
    p{
        margin:0;
        padding:0;
        a{
            color: var(--primary-yellow);
            text-decoration: underline;
        }
    }
    
}
  
.tooltip-container:hover .tooltip {
    visibility: visible;
}