@import "../../App.scss";
#bootstraping-anouncement{
    margin-bottom: 2em;
    font-size: .8em;
    font-weight: 700;
    text-align: center;
    line-height: 1.6em;
}
#value-locked-container{
    display: block;
    padding: 2em;
    margin-bottom: 3em;
    text-align: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    #value-locked-title{
        font-family: var(--text-font);
        font-size: 1.3em;
        font-weight: 700;
    }
    #value-locked-value{
        margin-top: 1em;
        font-family: var(--text-font);
        font-size: 2em;
        font-weight: 700;
    }
    
}

#vault-item-container{
    display: grid;
    align-items: center;
    padding: 1em 3em;
    margin-bottom: 1em;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    grid-template-columns: 25% 20% 20% 15% auto;
    cursor: pointer;
    font-weight: 700;
    #tkn-container{
        display: flex;
        float: left;
        align-items: center;
        #tkn-img{
            width: 2em;
            margin-right: .5em;
        }
    }
    #value{
        text-align: right;
    }
}
#vault-item-container:hover{
    box-shadow: rgba(249, 174, 59, 0.24) 0px 3px 8px;
}
a{
    #poweredbypolygon{
        width: 14em;
        margin-left:auto;
        margin-right:auto;
        display: block;
        margin-top: 15em;
    }
}


.title{
    cursor: default !important;
    color:#6C757D;
    box-shadow: none !important;
    margin-bottom: .5em !important;
    background-color: var(--primary-grey) !important;
    font-weight: 700 !important;
}