@import "../../App.scss";
#side-container{
    position: relative;
    display: block;
    width: 10em;
    height: 100vh;
    padding: 1.5em 2em;
    margin: 0;
    position: fixed;
    left:0;
    top:0;
    background-color: #FFFFFF;
    border-right: 1px solid #D0CFCF;
    #logo{
        
        display: flex;
        flex-wrap: wrap;
        #logo-img{
            margin-left: auto;
            margin-right: auto;
            width: 4em;
            float:left;
        } 
    
        #logo-text{
            margin-top: 2em;
            font-family: var(--logo-font);
            font-weight: 700;
            margin-left: auto;
            margin-right: auto;
            font-size: 1.5em;
        }

    }

    #primary-nav{
        padding:3em 0em;
        margin:0; 
    }
    #user-btn{
        position: absolute;
        bottom: 29%;
        width: calc(100% - 4em);
        align-self: center;
        justify-self: center;
        text-align: center;
        font-family: var(--text-font);
        font-weight: 700;
        a{
            color: #6C757D;
        }
        
    }

    #user-btn:hover{
        a{
            color: #212529;
        }
        
    }

    #doc-btn{
        position: absolute;
        bottom: 24%;
        width: calc(100% - 4em);
        align-self: center;
        justify-self: center;
        text-align: center;
        font-family: var(--text-font);
        font-weight: 700;
        a{
            color: #6C757D;
        }
        
    }
    #doc-btn:hover{
        a{
            color: #212529;
        }
        
    }

    #social-nav{
        width: calc(100% - 4em);
        display: flex;
        place-content: space-around;
        position: absolute;
        padding: 0em;
        bottom: 15%;
    }

    #badge-button{
        width: calc(100% - 4em);
        position: absolute;
        padding: 0em;
        bottom: 10%;
    }
}

a{
    text-decoration: none;
    color:#212529;
}

ul{
    list-style: none;
}

.active{
    //background-color: black !important;
    img{
        filter:none !important;
    }
    .side-btn-text{
        color:var(--primary-yellow) !important;
    }

}

.page-btn{
    display: flex;
    width: 100%;
    padding: .8em 0em;
    margin-left: 22px;
    align-items: center;
    //justify-content: center;
    img{
        display: none;
        filter: invert(84%) sepia(7%) saturate(344%) hue-rotate(169deg) brightness(87%) contrast(86%);
        float: left;
        width: 2em;
        margin-right: .8em;
    }
    .side-btn-text{
        display:flex;
        font-family: var(--text-font);
        font-size: 1.2em;
        font-weight: 700;
        color:#6C757D;
    }
}

.page-btn:hover{
    //background-color: #ECECEC;
    img{
        filter: invert(9%) sepia(42%) saturate(231%) hue-rotate(168deg) brightness(96%) contrast(87%);
    }
    .side-btn-text{
        color:#212529;
    }
}

.social-btn{
    img{
        width: 24px;
        filter: invert(84%) sepia(7%) saturate(344%) hue-rotate(169deg) brightness(87%) contrast(86%);
        float:left;
    }
}

.social-btn:hover{
    img{
        filter:none;
    }
}


@media only screen and (min-width:726px){
    
}


@media only screen and (min-width:1200px){
    
}

@media only screen and (min-width:1600px){
    
}