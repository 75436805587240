@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@700&display=swap');
:root{
    --main-color: #FFFFFF;
    --primary-yellow: #F9AE3B;
    --primary-grey: #F8F9FA;
    --logo-font: "Roboto Mono";
    --text-font: "Roboto";
    --text-weight: 400;
}

.primary-btn{
  outline: 0 !important;
  color:white;
  border:none;
  border-radius: 18px;
  font-family: var(--text-font);
  font-size: 1em;
  font-weight: 700;
  background-color: var(--primary-yellow);
  padding: .5em 1em;
  outline: none;
}
.primary-btn:hover{
  //box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: black;
}



//page container
//1240px : shrink sidebar
//768px : mobile view
@media only screen and (min-width:500px){
  .page-container{
    background-color: #F8F9FA;
    margin-left: 224px;
    padding: 1.5em 1em;
  }
  .content-container{
    padding-top: 3em !important;
    height: 110vh;
  }
}

@media only screen and (min-width:726px){
  .page-container{
    margin-left: 224px;
    padding: 1.5em 3em;
  }
  .content-container{
    padding-top: 3em !important;
  }
}


@media only screen and (min-width:1200px){
  .page-container{
    margin-left: 224px;
    padding: 1.5em 5em;
  }
  .content-container{
    padding-top: 3em !important;
  }
}

@media only screen and (min-width:1600px){
    .page-container{
      margin-left: 224px;
      padding: 1.5em 8em;
    }
    .content-container{
      padding-top: 3em !important;
    }
}